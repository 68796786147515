.table > :not(caption) > * > * {
  padding: 4px !important;
}

.table {
  margin-bottom: 4px !important;
}

.custom-card-ui .card-header {
  padding: 4px 16px !important;
}

.custom-card-ui .card {
  margin-bottom: 8px !important;
}

.custom-card-ui .card-body {
  padding: 4px !important;
}

table tbody tr td {
  padding: 5px !important;
}

table thead tr th {
  padding: 5px !important;
}

table thead tr th:first-child {
  padding-left: 10px !important;
}

table tbody tr td:first-child {
  padding-left: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.custom-picker {
  border-bottom: 1px solid #949494 !important;
  width: 180px;
}

.custom-picker-invalid {
  border-bottom: 1px solid #d42d2d !important;
  width: 180px;
}

/* .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl .MuiInputBase-input[value=""] {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: "Open Sans", sans-serif;

} */

.custom-zindex-div .flexible-modal {
  z-index: 10000 !important;
}

.custom-zindex-div .flexible-modal-mask {
  background: rgba(0, 0, 0, 0) !important;
}

div:where(.swal2-container) {
  z-index: 10001 !important;
}

.custom-primary-color {
  background-color: #1ba1a6 !important;
}

.custom-danger-color {
  background-color: #b7babf !important;
  border-color: #b7babf !important;
}

/************************* datepicker style **************************************/
.custom-picker input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.54) !important;
  opacity: 1 !important;
  font-family: "Open Sans", sans-serif !important;
  margin-top: 10px !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
}

.custom-picker-invalid input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.54) !important;
  opacity: 1 !important;
  font-family: "Open Sans", sans-serif !important;
  margin-top: 10px !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
}

.custom-picker input {
  padding: 8.5px 0px 4px 14px !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
}

.custom-picker-invalid input {
  padding: 8.5px 0px 4px 14px !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
}

/********************************************************************************/

/***********************Material input styles **********************************/
.MuiInputLabel-formControl {
  font-family: "Open Sans", sans-serif !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
}

.MuiInputBase-input {
  font-family: "Open Sans", sans-serif !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
}

.MuiButtonBase-root {
  font-family: "Open Sans", sans-serif !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
}

/*******************************************************************************/

.Status__control {
  min-height: 0px !important;
}

.Status__value-container {
  align-items: end !important;
  margin: 1px !important;
}

.custom-datatable {
  max-height: calc(100vh - 170px) !important;
}
.custom-datatable-450 {
  max-height: calc(100vh - 210px) !important;
}

.MuiTooltip-popper {
  z-index: 10000 !important;
}

.card-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.card-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.page-title-box {
  margin-bottom: 2px !important;
}
.card {
  margin-bottom: 2px !important;
}
.page-content {
  padding-bottom: 1px !important;
  padding-top: calc(15px + 1.5rem) !important;
}
.header-item {
  height: 35px !important;
}
.navbar-header {
  height: 35px !important;
}
.fixdashboard-fixed {
  top: 60px !important;
}
.page-titile-header h4 {
  font-weight: 700;
  font-size: 15px !important;
  text-transform: uppercase;
  margin-left: 20px;
}
.form-label {
  margin-top: 0.5rem !important;
}
/***********************************Custom error page ***********************************/

.error-body {
  height: 100vh;
  width: 100vw;
  background-image: url("../images/bg-error.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(180deg, #ffffff, #c7dae5); */
}

.error-img-wrapper {
  height: 40vh;
  width: auto;
}
.error-img-size {
  height: 40vh;
  width: auto;
}
.error-page-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 700;
}
.error-home-btn {
  background-color: #1ba1a6;
  padding: 10px 25px;
  border-radius: 22px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 650;
}

.global-input-group {
  width: auto !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.global-input-group .dash-filter-picker {
  min-width: 190px !important;
}

.global-input-text {
  background-color: transparent !important;
  border: 0 !important;
}

.global-date-input {
  background-color: transparent !important;
  border: 0 !important;
}

.swal-titleText {
  font-size: 25px !important;
}

.verify-input {
  height: 50px;
  font-size: 25px !important;
  font-weight: 600;
}

/* CustomeCheck Style */

.custome-toggle-switch-btn .switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Reduced width */
  height: 20px; /* Reduced height */
}

.custome-toggle-switch-btn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custome-toggle-switch-btn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 10px; /* Adjusted border-radius */
}

.custome-toggle-switch-btn .slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Reduced height */
  width: 16px; /* Reduced width */
  left: 2px; /* Adjusted left position */
  bottom: 2px; /* Adjusted bottom position */
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custome-toggle-switch-btn input:checked + .slider {
  background-color: rgb(27, 161, 166);
}

.custome-toggle-switch-btn input:focus + .slider {
  box-shadow: 0 0 1px rgb(27, 161, 166);
}

.custome-toggle-switch-btn input:checked + .slider:before {
  -webkit-transform: translateX(20px); /* Adjusted translation */
  -ms-transform: translateX(20px); /* Adjusted translation */
  transform: translateX(20px); /* Adjusted translation */
}

/* Rounded sliders */
.custome-toggle-switch-btn .slider.round {
  border-radius: 20px; /* Adjusted border-radius */
}

.custome-toggle-switch-btn .slider.round:before {
  border-radius: 50%;
}
